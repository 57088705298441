/**
 * @module orb/lib/_onloadCSS
 */

define('orb/lib/_onloadCSS', function () {
  /*
        onloadCSS: adds onload support for asynchronous stylesheets loaded with loadCSS.
		global navigator
		exported onloadCSS

        Version     : 1.2.0
        Author      : @zachleat, Filament Group, Inc.
        Homepage    : https://github.com/filamentgroup/loadCSS
        License     : MIT
    */

  function onloadCSS(ss, callback) {
    var called;
    function newcb() {
      if (!called && callback) {
        called = true;
        callback.call(ss);
      }
    }
    if (ss.addEventListener) {
      ss.addEventListener('load', newcb);
    }
    if (ss.attachEvent) {
      ss.attachEvent('onload', newcb);
    }

    // This code is for browsers that don’t support onload
    // No support for onload (it'll bind but never fire):
    //	* Android 4.3 (Samsung Galaxy S4, Browserstack)
    //	* Android 4.2 Browser (Samsung Galaxy SIII Mini GT-I8200L)
    //	* Android 2.3 (Pantech Burst P9070)

    // Weak inference targets Android < 4.4
    if ('isApplicationInstalled' in navigator && 'onloadcssdefined' in ss) {
      ss.onloadcssdefined(newcb);
    }
  }
  return onloadCSS;
});
